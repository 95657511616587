import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import ModalVideo from "react-modal-video";
import { VideoFrame } from "../../styles/components/VideoFrame";
import OptimizedImage from "../../utils/optimizedImage";
import { StaticImage } from "gatsby-plugin-image";
import { PlayButton } from "../../utils/IconsList";
import {
  VideoSectionWrapper,
  Content,
  TextContent,
  KickerWrapper,
  HeadingWrapper,
  SubheadingWrapper,
  ImageWrapper,
  ThumbnailWrapper,
  BackgroundWrapper,
} from "./styles/VideoSectionStandard.styled";

import onKeyDown from "../../utils/onKeyDown";
import {
  fetchCustomVideoMetadata,
  fetchVimeoData,
  fetchYoutubeData,
  formatDuration,
  getCustomVideoDuration,
  validatePlay,
} from "../../utils/validateVideo";
import setScript from "../../utils/setScript";
import { Helmet } from "react-helmet";

// Lazy load heavy scripts
const LazyModalVideo = lazy(() => import("react-modal-video"));

export const VideoSectionStandard = ({ component }) => {
  const {
    featuredVideo,
    kicker,
    videoHeading,
    subheadingText,
    background,
    javascriptSnippet,
    htmlSnippet,
  } = component;

  const backgroundColor = background?.toLowerCase();
  const jsSnip = javascriptSnippet?.javascriptSnippet;
  const htmlSnip = htmlSnippet?.htmlSnippet;
  const hasEmbed = jsSnip && htmlSnip;

  const [play, setPlay] = useState(false);
  const [videoId, setVideo] = useState(null);
  const [channel, setChannel] = useState("");
  const [duration, setDuration] = useState("");
  const [uploadDate, setUploadDate] = useState("");

  const playVideo = useCallback((video) => {
    validatePlay(video, setChannel, setVideo, setPlay);
  }, []);

  useEffect(() => {
    if (hasEmbed) {
      setScript(jsSnip);
    }
  }, [hasEmbed]);

  useEffect(() => {
    if (!videoId) return;

    const fetchVideoMetadata = async () => {
      let videoData = {};
      if (channel === "youtube") {
        videoData = await fetchYoutubeData(videoId);
      } else if (channel === "vimeo") {
        videoData = await fetchVimeoData(videoId);
      } else if (channel === "custom") {
        videoData.duration = await getCustomVideoDuration(videoId);
        videoData.uploadDate = await fetchCustomVideoMetadata(videoId);
      }

      setDuration(formatDuration(videoData?.duration || 0));
      setUploadDate(videoData?.uploadDate || "2025-01-01T12:00:00Z");
    };

    fetchVideoMetadata();
  }, [videoId, channel]);

  const schema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "embedUrl": featuredVideo?.videoFile?.file?.url
      ? `https:${featuredVideo.videoFile.file.url}`
      : featuredVideo?.videoUrl,
    "name": videoHeading || featuredVideo?.title || "Video Title",
    "description":
      subheadingText?.subheadingText ||
      featuredVideo?.thumbnail?.description ||
      "Video Description",
    "thumbnailUrl":
      featuredVideo?.thumbnail?.gatsbyImageData?.images?.sources[0]?.srcSet ||
      featuredVideo?.thumbnail?.file?.url ||
      "Video Thumbnail",
    "duration": duration,
    "uploadDate": uploadDate,
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <VideoSectionWrapper background={backgroundColor} noTopPadding={component?.noTopPadding}>
        <Content>
          <TextContent>
            {kicker && <KickerWrapper>{kicker}</KickerWrapper>}
            {videoHeading && <HeadingWrapper>{videoHeading}</HeadingWrapper>}
            {subheadingText?.subheadingText && (
              <SubheadingWrapper>{subheadingText?.subheadingText}</SubheadingWrapper>
            )}
          </TextContent>
          <ImageWrapper hasEmbed={hasEmbed}>
            {backgroundColor === "blue" && (
              <BackgroundWrapper>
                <StaticImage src="../../../static/images/video-section-background.webp" />
              </BackgroundWrapper>
            )}
            {!hasEmbed ? (
              <ThumbnailWrapper
                onClick={() => playVideo(featuredVideo)}
                role="button"
                aria-label="play"
                tabIndex={0}
                onKeyDown={(e) => onKeyDown(e, () => playVideo(featuredVideo), "Enter")}
              >
                <figure className="blog-image-wrapper">
                  {featuredVideo?.thumbnail?.url ? (
                    <OptimizedImage
                      image={featuredVideo?.thumbnail?.gatsbyImageData}
                      src={featuredVideo?.thumbnail?.url}
                      alt={
                        featuredVideo?.thumbnail?.description ||
                        featuredVideo?.thumbnail?.title ||
                        "featured-image"
                      }
                    />
                  ) : (
                    <div className="emptyImg" />
                  )}
                </figure>
                <div className="playicon">
                  <span>
                    <PlayButton />
                  </span>
                </div>
              </ThumbnailWrapper>
            ) : (
              <div className="embed-wrapper" dangerouslySetInnerHTML={{ __html: htmlSnip }} />
            )}
          </ImageWrapper>
        </Content>
        {typeof window !== "undefined" && play && (
          <VideoFrame>
            <Suspense fallback={<div>Loading Video...</div>}>
              <LazyModalVideo
                channel={channel}
                isOpen={play}
                videoId={videoId}
                url={channel === "custom" ? videoId : undefined}
                onClose={() => setPlay(false)}
                autoplay
              />
            </Suspense>
          </VideoFrame>
        )}
      </VideoSectionWrapper>
    </>
  );
};
